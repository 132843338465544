.live-stream-wrapper {
  position: relative;
  background-color: var(--background-secondary-color);
  height: 85vh;
  padding: 0px;
}

.live-stream-footer {
  position: absolute;
  bottom: 0;
  /* background-color: blue; */
  width: 100%;
}

.stream-controls-wrapper {
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px;
}

.stream-control {
  margin: 0px 5px;
}

.stream-control-button {
  background-color: var(--background-secondary-color);
  color: var(--portal-theme-primary);
  border: solid 2px var(--portal-theme-primary);
}

.stream-control-button-active {
  background-color: var(--portal-theme-primary);
  color: var(--background-secondary-color);
}

.stream-control-button:hover {
  background-color: var(--portal-theme-primary);
  color: var(--background-secondary-color);
}

.stream-control-button-active:hover {
  background-color: var(--portal-theme-primary);
  color: var(--background-secondary-color);
}

.stream-control-icon {
  font-size: 24px;
}

.stream-info {
  display: flex;
  align-items: center;
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: var(--background-secondary-color);
  color: white;
  padding: 2px 10px 2px 8px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 15px;
  border: solid 2px var(--portal-theme-primary);
}

.stream-users-info {
  display: flex;
  align-items: center;
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: var(--background-secondary-color);
  color: var(--portal-theme-primary);
  padding: 2px 10px 2px 8px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 15px;
  border: solid 2px var(--portal-theme-primary);
  z-index: 1;
  cursor: pointer;
}

.stream-users-info span {
  color: white;
}

.live-stream-label {
  display: flex;
  align-items: center;
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: var(--background-secondary-color);
  color: white;
  padding: 2px 10px 2px 8px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 15px;
  border: solid 2px var(--portal-theme-primary);
}

.participant-control-icon {
  font-size: 18px;
}

.live-stream-comments {
  padding: 20px;
  height: 85vh;
  background-color: var(--sidebars-background-color);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-y: hidden;
}

.live-stream-comments > .profile-comments {
  overflow-y: scroll;
}

.end-stream-btn {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 15px;
  color: white;
  padding: 2px 10px 2px 8px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 15px;
}

.settings-stream-btn {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 15px;
  color: white;
  padding: 2px 10px 2px 8px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 15px;
}

.live-stream-participants {
  color: #fff;
}

@media (max-width: 820px) {
  .live-stream-wrapper {
    height: 55vh;
  }
  .live-stream-comments {
    height: 50vh;
    margin-top: 25px;
  }
}

@media (max-width: 571px) {
  .live-stream-wrapper {
    height: 45vh;
  }
  .live-stream-logo {
    max-width: 80%;
  }
}
